import { useEffect, useState } from 'react'
import './App.css'
import { Input } from './Components/Input'

function App (){

  const [list, setList] = useState([])
  function dataHander(value){
    const obj = {
      item : value,
      status : false
    }
    // setList([...list, obj])
    postData(obj)
  }
  async function postData(obj){
    
    let res1 =  await fetch('http://localhost:7000/items',{
      method : "POST",
      body : JSON.stringify(obj),
      headers : {
        'content-type' : "application/json"
      }
    })
    await res1.json()
    getData()
  }
  async function DeleteItem(id){
    
    let res1 =  await fetch(`http://localhost:7000/items/${id}`,{
      method : "DELETE"
    })
    await res1.json()
    getData()
  }
  async function ToggleItem(data){
    const obj = {
      item : data.item,
      status : data.status?false: true,
      id : data.id
    }
    let res1 =  await fetch(`http://localhost:7000/items/${data.id}`,{
      method : "PATCH",
      body : JSON.stringify(obj),
      headers : {
        'content-type' : "application/json"
      }
    })
    await res1.json()
    getData()
  }
  async function getData(){
    let res1 = await fetch('http://localhost:7000/items')
    let res2 = await res1.json()
    setList(res2)
  }
  

  useEffect(()=>{
    getData()
  },[])
  return (
    <div className='App'>
      <h1>I am too crazy.</h1>
      <Input dataHander={dataHander}/>
      <h1>Your Added Item</h1>
      <table className='vijTable'>
        <thead>
          <tr>
            <th>Item</th>
            <th>Delete Status</th>
            <th>Toggle Status</th>
          </tr>
        </thead>
        <tbody>
          { list.map(el=>{
            return <tr key={el.id}>
              <td>{el.item}</td>
              <td><button onClick={()=>DeleteItem(el.id)}>Delete</button></td>
              <td><button onClick={()=>ToggleItem(el)}>{el.status ? 'DONE' : "NOT DONE"}</button></td>
            </tr>
          })}
        </tbody>
      </table>
    </div>
  )
}


export default App