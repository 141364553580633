import { useState } from "react"

export function Input({ dataHander}){

    const [name, setName] = useState("")
    function OnChangeHander(){
        dataHander(name)
    }
    return (
        <div>
            <input type="text" onChange={(e)=>{
                setName(e.target.value)
            }}/>
            <button onClick={()=>{
                OnChangeHander()
            }}>ADD</button>
        </div>
    )
}